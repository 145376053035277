/* eslint-disable max-len,max-lines */
import forIn from 'lodash/forIn'
import { checkEnvironment } from './Helpers'

export const queryParmeters = new URLSearchParams(window.location.search)
let BASE_URL = ''

if (checkEnvironment('test')) {
  BASE_URL = 'https://int.api.ellielabs.com'
} else if (checkEnvironment('dev')) {
  BASE_URL = 'https://dev.api.ellielabs.com'
} else if (checkEnvironment('qa')) {
  BASE_URL = 'https://qa.api.ellielabs.com'
} else if (checkEnvironment('peg')) {
  BASE_URL = 'https://peg.api.ellielabs.com'
} else if (checkEnvironment('stg')) {
  BASE_URL = 'https://stg.api.elliemae.com'
} else if (checkEnvironment('prod')) {
  BASE_URL = 'https://api.elliemae.com'
}
if (checkEnvironment('concept')) {
  BASE_URL = 'https://concept.api.elliemae.com'
}

export const methods = {
  DELETE: 'DELETE',
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
}

export const localEnvConfig = {
  defaultOrigin: `${BASE_URL}`,
  apiOrigin: `${BASE_URL}`,
}

export const localEnvAuthConfig = {
  authOrigin: `${BASE_URL}`,
  // grantAndScope: `username=${user}@${name}:${instance}&password=${password}&grant_type=${grantType}&client_id=${clientId}&client_secret=${clientSecret}`,
  tokenKey: 'INVESTOR_PORTAL_TOKEN',
}

export const retriableResponses = [408, 409, 500, 502, 503, 504]
export const multipleLoans = queryParmeters.has('multipleLoans') && queryParmeters.get('multipleLoans')
export const isLogs = queryParmeters.has('isLogs') && queryParmeters.get('isLogs')

/* --- Instances --- */
// BE11200922 // 19.2 int
// BE11172646 // 19.3 int
// BE11176282 // 19.4 int
// DEBE11214099 // ?
// DEBE11170913 // 20.1 int

const defaultCredentials = {
  user: queryParmeters.get('user') || 'admin',
  name: queryParmeters.get('name') || 'encompass',
  password: queryParmeters.get('password'),
  instance: queryParmeters.get('instance'),
}

export const users = {
  INT_bidirectionalPostUser: {
    authData: {
      grant_type: 'client_credentials',
      client_id: 'f455z573',
      client_secret: queryParmeters.get('client_post_secret'),
      scope: 'pc pcapi',
    },
  },
  STG_bidirectionalPostUser: {
    authData: {
      grant_type: 'client_credentials',
      client_id: 'l67k808i',
      client_secret: queryParmeters.get('client_post_secret'),
      scope: 'pc pcapi',
    },
  },
  default: {
    authData: {
      username: `${defaultCredentials.user}@${defaultCredentials.name}:${defaultCredentials.instance}`,
      password: defaultCredentials.password,
      grant_type: queryParmeters.get('grant_type') || 'password',
      client_id: queryParmeters.get('client_id') || 'kels7isq',
      client_secret: queryParmeters.get('client_secret'),
    },
  },
}

export const login = `${BASE_URL}/oauth2/v1/token`
export const executePostman = (resourceId) => `${BASE_URL}/partner/v1/transactions/${resourceId}/response`

export const authBody = (user) => {
  let parsedAuthBody = ''
  forIn(user.authData, (value, key) => {
    parsedAuthBody += `${key}=${value}&`
  })
  return parsedAuthBody
}

export const loggerURL = `${BASE_URL}/investor/v1/logger`
export const loggerAPIflag = true
export const updateStackingTemplateParam = (stackingTemplateId) => `${BASE_URL}/encompass/v1/settings/efolder/documentStackingTemplates/${stackingTemplateId}`

export const key = 'eic_application'

/* --- Loan IDs --- */
// b60ca290-ac00-4d0d-9bbf-2eea622d9101
// 308e9636-1a0b-4021-aa98-73abaaaf5dd2
// c8dd7a91-4b9c-4960-bbee-3ada131a9ac8
// 640e2c03-2cdb-4c9e-8e9a-42c4c1c47d20
// 30327607-9308-4035-b13a-8cba5aa7a8e2
// 3beef852-3b7b-4a2c-82c8-f600e467d732

export const getParameters = () => ({
  host: {
    name: 'loConnect',
    links: [{
      title: 'Conditions',
      pageId: 'loconditions',
    }],
  },
  encompass: {
    pipeline: {
      filteredLoanCount: 'number',
      filter: {
        operator: 'AND',
        terms: [
          {
            canonicalName: 'Loan.CurrentMilestoneName',
            value: [
              'Started',
            ],
            matchType: 'Multivalue',
          },
          {
            canonicalName: 'Loan.LastModified',
            value: '2021-2-23T13:55:00Z',
            matchType: 'greaterThanOrEquals',
            precision: 'minute',
          },
          //   {
          //     "canonicalName": "Loan.LastModified",
          //     value: '2021-2-23T18:30:00Z',
          //     "matchType": "GreaterThanOrEquals",
          //     "precision": "Day"
          // },
          // {
          //     "canonicalName": "Loan.LastModified",
          //     value: '2021-2-23T18:30:00Z',
          //     "matchType": "LessThan",
          //     "precision": "Day"
          // }
          {
            operator: 'OR',
            terms: [
              {
                canonicalName: 'LoanFolder.Trash',
                value: 'N',
                matchType: 'exact',
              },
              {
                canonicalName: 'LoanFolder.Archive',
                value: 'N',
                matchType: 'exact',
              },
            ],
          },
        ],
      },
      sortOrder: [
        {
          canonicalName: 'Loan.BorrowerName',
          order: 'Ascending',
        },
      ],
    },
    loans: multipleLoans
      ? [
        {
          id: '029fa056-ba4a-461e-b372-6107351f4b33',
          loanNumber: '1802EM17143132',
          lockId: '8ad5f4896ca5bda99f5371',
        },
        {
          id: '85dca6b2-ac7e-4cf6-951e-c59aedd408b3',
          loanNumber: '1802EM17143133',
          lockId: '8ad5f4896ca5bda99f5371',
        },
      ]
      : [
        {
          id: queryParmeters.get('customLoan') || 'b60ca290-ac00-4d0d-9bbf-2eea622d9101',
          loanNumber: '1802EM17143132',
          lockId: '8ad5f4896ca5bda99f5371',
          enhancedConditionEnabled: true,
        },
      ],
    user: {
      id: `${defaultCredentials.name}\\${defaultCredentials.instance}\\${defaultCredentials.user}`,
    },
    version: '20.1.0.0',
  },
  errorMessages: [],
  condition: {
    group: isLogs
      ? {
        id: 'test value',
      }
      : {},
    eFolder: !isLogs
      ? {
        conditionType: 'UNDERWRITING',
      }
      : {},
    message: {
      id: 'test value',
    },
  },
  partner: {
    //
    // v1
    partnerId: '007004',
    productName: 'DataDocs_UC17',
    providerName: 'DataDocs_UC17',
    integrationType: 'EPC',
    configurationName: 'Test',
    activeVersion: '6',
    vendorKey: 'VPDM.Partner.Connect',
    providerCompanyCode: '007004DataDocs_UC17',
    freddieMacCreditProviderParentCode: '',
    serviceMode: '1',
    fannieMaeCreditProviderCode: '',
    freddieMacCreditProviderAffiliateCode: '',
    isGenerallyAvailable: 'true',
    status: 'INTESTING',
    partnerInfoUrl: '',
    partnerLogoUrl: '',
    category: 'Investor',
    originWorkflow: '',
    partnerConfigurationUrl: 'https://int.api.ellielabs.com/services/v1/partners/007004/landingPage',
    displayName: 'DataDocs_UC17',
    clientEntity: {
      configName: 'Test',
      status: 'INTESTING',
      activeVersion: '1',
      isGenerallyAvailable: 'true',
    },
    digiCert: 'false',
    tags: [],
    partnerWebUIUrl: 'https://int.api.ellielabs.com/services/v1/partners/007004/ui?productName=DataDocs_UC17&configName=Test&version=6',
  },
})

export const errorMessages = {
  tryAgain: (field) => `Unable to retrieve ${field}. Please try again.`,
  requiredText: 'Error',
  api: 'Unable to process the request.',
  fields: (field) => `Error Retrieving ${field}`,
  submissionError: 'Error',
  noDocuments: 'Documents missing from some loans. Choose Review to add documents, or modify stacking template.',
}
